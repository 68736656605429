import React from 'react';
import Header from './components/Header';
import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import Upload from './routes/upload';
import Review from './routes/review';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path={`/`} element={<></>} />
        <Route path={`/upload`} element={<Upload/>} />
        <Route path={`/review`} element={<Review/>} />   
      </Routes>
    </div>
  );
};

export default App;
