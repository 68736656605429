import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, shorthands } from '@fluentui/react-components';
import {
  Button,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  header: {
    ...shorthands.padding('10px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#5c5c5c',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    gap: '10px',
  },
  menuButton: {
    color: '#FFF',
  },
});

const Header: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <header className={styles.header}>
      <h1>Gestion des Mill Tests</h1>
      <nav className={styles.nav}>
        <Button className={styles.menuButton} onClick={() => handleNavigation('/')} appearance="primary">Accueil</Button>
        <Button className={styles.menuButton} onClick={() => handleNavigation('/upload')} appearance="primary">Importer</Button>
        <Button className={styles.menuButton} onClick={() => handleNavigation('/review')} appearance="primary">Réviser</Button>
      </nav>
    </header>
  );
};

export default Header;
