import { useState } from 'react';
import type { FC, FormEvent } from 'react';
import axios from 'axios';
import {
  makeStyles,
  tokens,
  Field,
  Input,
  Button
} from '@fluentui/react-components';
import { SearchFilled, SubtractCircleRegular } from '@fluentui/react-icons';
import MillTestsDataGrid, { type Item, transformItems } from './MillTestsDataGrid';

const useStyles = makeStyles({
  root: {
    display: "grid",
    gap: "10px",
    marginBottom: "15px",
    textAlign: "left",
    fontWeight: "bold",
  },
  leftAlign: {
    textAlign: "left"
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  buttonRed: {
    color: 'white',
    backgroundColor: tokens.colorStatusDangerBackground3,
    ':hover': {
      color: 'white',
      backgroundColor: tokens.colorStatusDangerBackground3Hover
    },
  },
  errorMessage: {
    color: tokens.colorStatusDangerForeground3,
    fontSize: '13px',
    marginTop: '-15px'
  }
})

const MillTestSearch: FC = () => {
  const [loading, setLoading] = useState(false);
  const [testId, setTestId] = useState("");
  const [items, setItems] = useState([] as Item[]);
  const [errorMessage, setErrorMessage] = useState("");

  const styles = useStyles();

  const fetchMillTest = async (testId: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests/${testId}`);
      const data = response.data;
      console.log(data);
      setItems([...items, transformItems({id: testId, ...data})]);
    } catch (error) {
      const errorMsg = `Aucun Mill Test avec ID "${testId}" n'a été trouvé.`;
      setErrorMessage(errorMsg);
      console.log(error);
    }
  };

  const handleSubmit = async (event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    const id = testId;
    setTestId("");
    setErrorMessage("");
    await fetchMillTest(id);
    setLoading(false);
  };

  const handleClear = () => {
    setItems([]);
    setErrorMessage("");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Field label="Recherche" id="recherche-id" className={styles.root}>
          <Input
            autoComplete="off"
            placeholder="Rechercher par Test ID"
            value={testId}
            onChange={(event) => setTestId(event.target.value)}
          />
        </Field>
    {errorMessage && (
      <div className={styles.errorMessage}>
        {errorMessage}
      </div>
    )}
    <div className={styles.buttonGroup}>
      <Button disabled={!testId || loading} appearance='primary' type='submit' icon={<SearchFilled />}>
        Recherche
      </Button>
      {items.length > 0 && (
        <Button icon={<SubtractCircleRegular />} className={styles.buttonRed} onClick={handleClear}>
          Effacer
        </Button>
      )}
    </div>
    </form>
    {items.length > 0 && (
      <MillTestsDataGrid items={items} />
    )}
    </>
  );
}

export default MillTestSearch;
