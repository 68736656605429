import React from 'react';
import UploadForm from '../components/UploadForm';
import SearchForm from '../components/MillTestSearch';
import { makeStyles, Divider } from '@fluentui/react-components';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
    '@media(max-width: 600px)': {
      padding: '10px',
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    margin: '0 auto',
    maxWidth: '600px',
    '@media(max-width: 600px)': {
      padding: '10px',
    },
  },
});

const Upload: React.FC = () => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.formContainer}>
        <UploadForm />
      </div>
      <Divider inset />
      <div className={styles.searchContainer}>
        <SearchForm />
      </div>
    </>
  );
}

export default Upload;
