import type { FC } from 'react';
import {
  type SortDirection,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn
} from "@fluentui/react-components";

// Define the types for the cell data
type IdCell = {
  label: string;
};

type TypeCell = {
  label: string;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type StatusCell = {
  label: string;
};

type ProvenanceCell = {
  label: string;
};

type FileLinkCell = {
  label: string;
  link: string;
};

type DiameterCell = {
  label: string;
};

export type Item = {
  id: IdCell;
  type: TypeCell;
  provenance: ProvenanceCell;
  lastUpdated: LastUpdatedCell;
  status: StatusCell;
  fileLink: FileLinkCell;
  diameter: DiameterCell;
};

export const transformItems = (test: any) => ({
  id: {
    label: test.id,
  },
  type: {
    label: test.type,
  },
  provenance: {
    label: test.provenance,
  },
  lastUpdated: {
    label: new Date(test.updated_at).toLocaleString(),
    timestamp: new Date(test.updated_at).getTime(),
  },
  status: {
    label: test.status,
  },
  fileLink: {
    label: 'File',
    link: test.sharepoint_url,
  },
  diameter: {
    label: test.diameter.toString(),
  },
});


// Create the columns definition
const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
        columnId: "provenance",
        compare: (a, b) => a.provenance.label.localeCompare(b.provenance.label),
        renderHeaderCell: () => <strong>Provenance</strong>,
        renderCell: (item) => <TableCellLayout>{item.provenance.label}</TableCellLayout>,
    }),
    createTableColumn<Item>({
    columnId: "diameter",
    compare: (a, b) => parseInt(a.diameter.label) - parseInt(b.diameter.label),
    renderHeaderCell: () => <strong>Diamètre</strong>,
    renderCell: (item) => <TableCellLayout>{item.diameter.label}</TableCellLayout>,
  }),
  createTableColumn<Item>({
    columnId: "type",
    compare: (a, b) => a.type.label.localeCompare(b.type.label),
    renderHeaderCell: () => <strong>Type</strong>,
    renderCell: (item) => <TableCellLayout>{item.type.label}</TableCellLayout>,
  }),
  createTableColumn<Item>({
    columnId: "fileLink",
    renderHeaderCell: () => <strong>Fichier</strong>,
    renderCell: (item) => (
    <TableCellLayout>
        <a href={item.fileLink.link} target="_blank" rel="noopener noreferrer">
            {item.id.label}
        </a>
      </TableCellLayout>
    ),
  }),
  createTableColumn<Item>({
    columnId: "lastUpdated",
    compare: (a, b) => a.lastUpdated.timestamp - b.lastUpdated.timestamp,
    renderHeaderCell: () => <strong>Dernière modification</strong>,
    renderCell: (item) => item.lastUpdated.label,
  }),
  createTableColumn<Item>({
      columnId: "status",
      renderHeaderCell: () => <strong>Statut</strong>,
      renderCell: (item) => <TableCellLayout>{item.status.label}</TableCellLayout>,
  }),
];

const defaultSortState = {sortColumn: 'lastUpdated', sortDirection: 'ascending' as SortDirection};

const MillTestsDataGrid: FC<{items: Item[]}> = ({items}) => (
  <DataGrid
        items={items}
        columns={columns}
        defaultSortState={defaultSortState}
        sortable
        resizableColumns
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<Item>>
        {({ item, rowId }) => (
          <DataGridRow<Item> key={rowId}>
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
);

export default MillTestsDataGrid;
