import React from 'react';
import MillTestsTable from '../components/MillTestsTable';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    '@media(max-width: 600px)': {
      padding: '10px',
    },
  },
});

const Review: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.formContainer}>
      <MillTestsTable />
    </div>
  );
}

export default Review;
