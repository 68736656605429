import { type FC, useEffect, useState } from 'react';
import axios from "axios";
import { ProgressBar, Skeleton, Text } from '@fluentui/react-components';

import MillTestsDataGrid, { type Item, transformItems } from './MillTestsDataGrid';


// Component to fetch and display the table
const MillTestsTable: FC = () => {
  const [items, setItems] = useState([] as Item[]);
  const [loading, setLoading] = useState(true);

  // TODO (afroserom): move data fetching to page level

  // Fetch data from the API
  useEffect(() => {
    const fetchMillTests = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests?status=under_review`);
        const data = response.data;

        // Transform the data to match the expected structure
        const transformedItems = data.map(transformItems);

        setItems(transformedItems);
      } catch (error) {
        console.error("Failed to fetch mill tests", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMillTests();
  }, []);

  if (loading) {
    return (
      <div>
        <ProgressBar thickness="medium" />
        <Skeleton animation="wave" />
      </div>
    );
  }

  return (
    <div>
      {items.length === 0 ? (
        <Text>Aucun mill test à réviser</Text>
      ) : (
        <MillTestsDataGrid items={items} />
      )}
    </div>
  );
};

export default MillTestsTable;
