import React, { useState, useEffect } from 'react';
import { Combobox, Option, makeStyles, Link, Button, Input, Switch, Field, InfoLabel, MessageBar, tokens, MessageBarBody, MessageBarTitle, MessageBarActions, Spinner } from '@fluentui/react-components';
import { SendFilled, DismissRegular } from "@fluentui/react-icons";
import type { MessageBarIntent, ComboboxProps } from '@fluentui/react-components';
import {
  Virtualizer,
  useStaticVirtualizerMeasure,
} from "@fluentui/react-components/unstable";
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    display: "grid",
    gap: "10px",
    marginBottom: "15px",
    textAlign: "left",
    fontWeight: "bold",
  },
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    marginLeft: '8px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  leftAlign: {
    textAlign: "left",
  },
  messageBarContainer: {
    marginTop: '15px',
    textAlign: 'left',
  },
  option: {
    height: "32px",
  },
  listbox: {
    maxHeight: "250px",
  },
  messageBarGroup: {
    padding: tokens.spacingHorizontalMNudge,
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    gap: "10px",

    height: "300px",
    overflow: "auto",
    border: `2px solid ${tokens.colorBrandForeground1}`,
  },
  fileInputContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  fileInput: {
    position: 'absolute',
    left: '-9999px',
    opacity: '0',
  },
  fileInputLabel: {
    display: 'inline-block',
    padding: '0 6px',
    background: '#f0f0f0',
    border: '1px solid #888',
    borderRradius: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
  },
  fileInputInfo: {
    fontWeight: 'normal',
    font: '0.8rem "Courrier News", sans-serif',
    marginLeft: '5px'
  }
});



const provenanceOptions = ["imported", "canadian"];
const typeOptions = ["400L", "400LGP", "400St", "400W", "400WGP", "400WE", "500W"];
const diameterOptions = ["10", "15", "20", "25", "30", "35", "45", "55"];

const UploadForm: React.FC = () => {
  const styles = useStyles();
  const [isBatchUpload, setIsBatchUpload] = useState(false);
  const [provenance, setProvenance] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string | undefined>(undefined);
  const [diameter, setDiameter] = useState<string | undefined>(undefined);
  const [testFile, setTestFile] = useState<File | null>(null);
  const [overrideFileName, setOverrideFileName] = useState<string | undefined>('');
  const [metadataFile, setMetadataFile] = useState<File | null>(null);
  const [testFiles, setTestFiles] = useState<File | null>(null);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const [messageBarIntent, setMessageBarIntent] = useState<MessageBarIntent>('error');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  // we handle this as a string array for compatibility with the Combobox component
  const [selectedDiameterOptions, setSelectedDiameterOptions] = React.useState<string[] | undefined>([]);
  const [selectedProvenanceOptions, setSelectedProvenanceOptions] = React.useState<string[] | undefined>([]);
  const [selectedTypeOptions, setSelectedTypeOptions] = React.useState<string[] | undefined>([]);
  // this serves as a key to force the form to rerender and reset the inputs
  const [formKey, setFormKey] = useState(0);

  // const handleProvenanceChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
  //   setProvenance(ev.target.value);
  // };

  // const handleTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
  //   setType(ev.target.value);
  // };

  // const handleDiameterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
  //   setDiameter(ev.target.value);
  // };

  const handleTestFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestFile(event.target.files?.[0] || null);
  };

  const handleMetadataFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetadataFile(event.target.files?.[0] || null);
  };

  const handleTestFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestFiles(event.target.files?.[0] || null);
  };

  const handleOverrideFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverrideFileName(event.target.value);
  };

  const onDiameterOptionSelect: Partial<ComboboxProps>["onOptionSelect"] = (ev, data) => {
    setSelectedDiameterOptions(data.selectedOptions);
    setDiameter(data.optionText ?? undefined);
  };

  const onProvenanceOptionSelect: Partial<ComboboxProps>["onOptionSelect"] = (ev, data) => {
    setSelectedProvenanceOptions(data.selectedOptions);
    setProvenance(data.optionText ?? undefined);
  };

  const onTypeOptionSelect: Partial<ComboboxProps>["onOptionSelect"] = (ev, data) => {
    setSelectedTypeOptions(data.selectedOptions);
    setType(data.optionText ?? undefined);
  };

  // create hook to update the override whenever the test file changes

  const parseFileName = (file: File) => {
    const splitFile = file.name.split('.');
    splitFile.pop();
    return splitFile.join('.');
  };

  useEffect(() => {
    if (testFile) {
      const fileName = testFile.name;
      const id = fileName.split('.')[0];
      setOverrideFileName(id);
    }
  }, [testFile]);

  useEffect(() => {
    if (isBatchUpload) {
      if (metadataFile && testFiles) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      if (testFile && provenance && type && diameter && overrideFileName) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [isBatchUpload, metadataFile, testFiles, testFile, provenance, type, diameter, overrideFileName]);


  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    try {
      if (!isBatchUpload) {
        const formData = new FormData();
        if (testFile) {
          formData.append('file', testFile);
        }
        formData.append('provenance', provenance || '');
        formData.append('type', type || '');
        formData.append('diameter', diameter || '');

        await axios.post(`${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests/${overrideFileName}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        const formData = new FormData();
        if (testFiles) {
          formData.append('files', testFiles);
        }
        if (metadataFile) {
          formData.append('metadata', metadataFile);
        }

        await axios.post(`${process.env.REACT_APP_MILL_TESTS_API_URL}/mill_tests/batch`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      // TODO (afroserom): handle batch upload (metadata and pdf files)
      setFeedbackMessage(`Uploaded ${overrideFileName} test`);
      setMessageBarIntent('success');
      hardFormReset();
    } catch (error) {
      console.error(error);
      setFeedbackMessage('Upload failed. Please try again.');
      setMessageBarIntent('error');
    } finally {
      setLoading(false);
      setTimeout(() => setFeedbackMessage(null), 3000);
    }
  };

  const dismissMessage = () => {
    setFeedbackMessage(null);
  };

  const hardFormReset = () => {
    setSelectedDiameterOptions([]);
    setSelectedProvenanceOptions([]);
    setSelectedTypeOptions([]);
    setProvenance(undefined);
    setType(undefined);
    setDiameter(undefined);
    setTestFile(null);
    setOverrideFileName('');
    setMetadataFile(null);
    setTestFiles(null);
    // this forces the form to rerender and reset the inputs
    setFormKey(prevKey => prevKey + 1);
  };

  const itemHeight = 32;

  const { virtualizerLength, bufferItems, bufferSize, scrollRef } =
    useStaticVirtualizerMeasure({
      defaultItemSize: itemHeight,
      direction: "vertical",
    });

  return (
    <form onSubmit={handleSubmit} key={formKey}>
      <Field label={isBatchUpload ? "Importation multiple" : "Importation simple"} id="batch-upload-label" className={styles.leftAlign} style={{fontWeight: "bold"}}>
        <Switch
          checked={isBatchUpload}
          onChange={() => setIsBatchUpload(!isBatchUpload)}
          className={styles.root}
        />
      </Field>
      {isBatchUpload ? (
        <div>
          <Field label={
          // open link in new tab
          <InfoLabel info={<Link href="https://docs.google.com/spreadsheets/d/16SVCbY9wlxDTT6ZhLqUsFYIicJ0wP_lsY73Q1wO6cWk/edit?usp=sharing" target="_blank" >voir le modèle</Link>} tabIndex={-1}>
                          Fichier Metadata CSV
          </InfoLabel>}
                 id="metadata-label"
                 required
                 className={styles.root}
          >
            <div className={styles.fileInputContainer}>
              <input
                className={styles.fileInput}
                type="file"
                id="file-upload-metadata"
                accept=".csv"
                onChange={handleMetadataFileChange}
              />
              <label className={styles.fileInputLabel} htmlFor="file-upload-metadata">Choisir un fichier</label>
              <span className={styles.fileInputInfo}>{metadataFile ? parseFileName(metadataFile) : 'Aucun fichier choisi'}</span>
            </div>
          </Field>
          <Field label="Fichier Zip ou Tar contenant les certificats" id="test-files-label" required className={styles.root}>
            <div className={styles.fileInputContainer}>
              <input
                className={styles.fileInput}
                type="file"
                id="file-upload-batch"
                accept=".zip,.tar"
                onChange={handleTestFilesChange}
              />
              <label className={styles.fileInputLabel} htmlFor="file-upload-batch">Choisir un fichier</label>
              <span className={styles.fileInputInfo}>{testFiles ? parseFileName(testFiles) : 'Aucun fichier choisi'}</span>
            </div>
          </Field>
        </div>
      ) : (
        <div>
          <Field label="Provenance" id="provenance-label" required className={styles.root}>
            <Combobox
              aria-labelledby="provenance-label"
              placeholder="Selectionner la provenance"
              // value={provenance}
              selectedOptions={selectedProvenanceOptions}
              // onInput={handleProvenanceChange}
              onOptionSelect={onProvenanceOptionSelect}
            >
              {provenanceOptions.map(option => (
                <Option key={option}>{option}</Option>
              ))}
            </Combobox>
          </Field>
          <Field label="Diamètre" id="diameter-label" required className={styles.root}>
            <Combobox
              aria-labelledby="diameter-label"
              placeholder="Selectionner le diamètre"
              // value={diameter}
              selectedOptions={selectedDiameterOptions}
              // onInput={handleDiameterChange}
              onOptionSelect={onDiameterOptionSelect}
              listbox={{ ref: scrollRef, className: styles.listbox }}
            >
              <Virtualizer
                numItems={diameterOptions.length}
                virtualizerLength={virtualizerLength}
                bufferItems={bufferItems}
                bufferSize={bufferSize}
                itemSize={itemHeight}
              >
                {(index) => {
                  return (
                    <Option
                      className={styles.option}
                      aria-posinset={index}
                      aria-setsize={diameterOptions.length}
                      key={`item-${index}`}
                    >
                      {diameterOptions[index]}
                    </Option>
                  );
                }}
              </Virtualizer>
            </Combobox>
          </Field>
          <Field label="Type" id="type-label" required className={styles.root}>
            <Combobox
              aria-labelledby="type-label"
              placeholder="Selectionner le type"
              // value={type}
              selectedOptions={selectedTypeOptions}
              // onInput={handleTypeChange}
              onOptionSelect={onTypeOptionSelect}
            >
              {typeOptions.map(option => (
                <Option key={option}>{option}</Option>
              ))}
            </Combobox>
          </Field>
          <Field label="Fichier du rapport" id="test-file-label" required className={styles.root}>
            {/* accept pdf or image */}
            <div className={styles.fileInputContainer}>
              <input
                className={styles.fileInput}
                type="file"
                id="file-upload"
                accept="application/pdf, image/*"
                onChange={handleTestFileChange}
              />
              <label className={styles.fileInputLabel} htmlFor="file-upload">Choisir un fichier</label>
              <span className={styles.fileInputInfo}>{testFile ? parseFileName(testFile) : 'Aucun fichier choisi'}</span>
            </div>
          </Field>
          <Field 
            label={
              <InfoLabel info="Ceci permet de remplacer l'ID générée par le nom du fichier" tabIndex={-1}>
                               Test ID
              </InfoLabel>
            } 
            id="test-id-label" 
            required 
            className={styles.root}
          >
            <Input
              value={overrideFileName}
              onChange={handleOverrideFileNameChange}
            />
          </Field>
        </div>
      )}
      <div className={styles.leftAlign} >
        <Button disabled={disableSubmit} appearance='primary' type='submit' icon={ loading ? <Spinner size="extra-tiny"/> : <SendFilled/>} >Importer</Button>
      </div>
      {feedbackMessage && (
        <div className={styles.messageBarContainer}>
          <MessageBar intent={messageBarIntent}>
            <MessageBarBody>
              <MessageBarTitle>Result:</MessageBarTitle>
              {feedbackMessage}
              {/* <ProgressBar value={0.5} /> */}
            </MessageBarBody>
            <MessageBarActions
              containerAction={
                <Button onClick={() => dismissMessage()} appearance="transparent" icon={<DismissRegular />} />
              }
            >
            </MessageBarActions>
          </MessageBar>
        </div>
      )}
    </form>
  );
};

export default UploadForm;
